import React from 'react';
import { components } from 'react-select';
import CustomTooltip from './CustomTooltip';

export const MultiValueLabel = (props: any) => {
    const { data } = props;

    return (
        <CustomTooltip title={data.label}>
            <div>
                <components.MultiValueLabel {...props} />
            </div>
        </CustomTooltip>
    );
};
