import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import SectionTitle from '../SectionTitle';
import { ICON, SvgIcon } from '../SvgIcon';
import Button from '../Button';
import NoDataText from '../NoDataText';

interface OwnProps extends WithTranslation {
    filters: Array<Record<string, any>>;
    filterSelectedId: number | null;
    onFilterClick: (filter: Record<string, any>) => void;
    onDeleteFilterClick: (filter: Record<string, any>) => void;
    onCreateNewReportClick: () => void;
    showCreateBtn: boolean;
}

class ReportsSidebar extends Component<OwnProps> {
    onDeleteFilterClick = (e: React.MouseEvent, filter: Record<string, any>) => {
        e.stopPropagation();
        const { onDeleteFilterClick } = this.props;
        onDeleteFilterClick(filter);
    }

    onFilterClick = (filter: Record<string, any>) => {
        const { onFilterClick } = this.props;
        onFilterClick(filter);
    }

    onCreateNewClick = () => {
        const { onCreateNewReportClick } = this.props;
        onCreateNewReportClick();
    }

    handleSavedFiltersKeydown = (event: React.KeyboardEvent) => {
        const { key } = event;
        const { filterSelectedId, filters } = this.props;

        const filtersArray = filters.map((f) => {
            return f.id;
        });
        const currentSelectedFilterIndex = filtersArray.indexOf(filterSelectedId);
        const nextFilterIndex = currentSelectedFilterIndex + 1 <= (filtersArray.length - 1) ? currentSelectedFilterIndex + 1 : filtersArray.length - 1;
        const prevFilterIndex = currentSelectedFilterIndex - 1 <= 0 ? 0 : currentSelectedFilterIndex - 1;

        if (key && key.toLowerCase() === 'arrowdown' && nextFilterIndex !== currentSelectedFilterIndex) {
            this.onFilterClick(filters[nextFilterIndex]);
        }

        if (key && key.toLowerCase() === 'arrowup' && prevFilterIndex !== currentSelectedFilterIndex) {
            this.onFilterClick(filters[prevFilterIndex]);
        }
    }

    render() {
        const {
            filters, filterSelectedId, t,
            showCreateBtn,
        } = this.props;
        return (
            <div className="app-screen_content__list-wrapper reports-screen">
                <div className="app-screen_content__list-wrapper__top">
                    <SectionTitle title={t('reports.reportBuilderTitle')} icon={ICON.REPORT_BUILDER} />
                </div>
                {filters.length > 0 ? (
                    <div className="report-saved-filters" tabIndex={-1} onKeyDown={this.handleSavedFiltersKeydown}>
                        <div className="report-saved-filters__list" data-testid="saved-filters-list-wrapper">
                            {Object.keys(filters).map((k) => {
                                const filter = filters[Number(k)];
                                return (
                                    <div
                                        key={k}
                                        className={filterSelectedId && filter.id === filterSelectedId ? 'report-saved-filters__list__item selected' : 'report-saved-filters__list__item'}
                                        onClick={() => this.onFilterClick(filter)}
                                    >
                                        <p>{filter.name}</p>
                                        <SvgIcon icon={ICON.TRASH} callback={(e) => this.onDeleteFilterClick(e, filter)} testId={`delete-filter-${filter.id}-icon`} tooltip={t('global.delete')} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <NoDataText text={t('reports.noSavedReports')} />
                )}
                {
                    showCreateBtn && (
                        <div className="create-new-report-btn">
                            <Button
                                text={t('reports.buttons.createNewFilterSetting')}
                                styles="btn--blue"
                                callback={this.onCreateNewClick}
                                testId="create-new-filter-btn"
                                icon={ICON.PLUS}
                                iconPosition="left"
                            />
                        </div>
                    )
                }
            </div>
        );
    }
}

export default withTranslation()(ReportsSidebar);
