import React from 'react';
import { components } from 'react-select';
import { ICON, SvgIcon } from './SvgIcon';

export const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <SvgIcon className="small" icon={props.selectProps.menuIsOpen ? ICON.ARROW_UP : ICON.ARROW_DOWN} />
        </components.DropdownIndicator>
    );
};
