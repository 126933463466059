import { REHYDRATE } from 'redux-persist';

import {
    AuthenticationAction,
} from '../actions/authentication_types';
import { GeneralAction } from '../actions/general_types';
import { AuthenticationState } from './types';

/**
 * @typedef {AuthenticationState} authenticationInitialState
 */
export const authenticationInitialState: AuthenticationState = {
    user: null,
    isFetching: false,
    formErrors: null,
    wasLogout: false,
    allCustomers: [],
};

/**
 * authentication reducer
 * @param {AuthenticationState} state
 * @param {any} action
 * @returns {AuthenticationState}
 */
export default function (state = authenticationInitialState, action: any): AuthenticationState {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.authentication) {
                return {
                    ...action.payload.authentication,
                    isFetching: false,
                    formErrors: null,
                    wasLogout: false,
                };
            }
            return state;
        case AuthenticationAction.LOGOUT_REQUEST:
            return {
                ...authenticationInitialState,
                wasLogout: true,
            };
        case AuthenticationAction.LOGIN_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isFetching: false,
                isAuthenticated: true,
                formErrors: null,
                wasLogout: state.wasLogout,
            };
        case AuthenticationAction.SET_AUTHENTICATION:
            return {
                ...state,
                ...action.payload,
                wasLogout: state.wasLogout,
            };
        case AuthenticationAction.RESET_AUTHENTICATION:
            return authenticationInitialState;
        case AuthenticationAction.SET_USER:
            return {
                ...state,
                user: { ...state.user, ...action.payload },
                wasLogout: state.wasLogout,
            };
        case AuthenticationAction.SET_CUSTOMERS:
            return {
                ...state,
                allCustomers: action.payload,
            };
        case GeneralAction.RESET_STORE:
            return { ...authenticationInitialState };
        default:
            return state;
    }
}
