import { Popover } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DCCState, OpenItemRequest, ProcessState } from '../../../constants/report';
import { ReportType } from '../../../constants/types';
import SectionTitle from '../SectionTitle';
import { SvgIcon } from '../SvgIcon';

interface OwnProps extends WithTranslation {
    filters: Record<string, any>;
    filterType: ReportType | OpenItemRequest;
    anchorElem: HTMLElement | null;
    onClosePopover(): void;
    testId?: string;
}

const FiltersAppliedPopover: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        onClosePopover, filterType, filters, t, anchorElem, testId,
    } = props;

    const renderFilters = () => {
        if (filters) {
            const {
                columns, tenantsIds, applicationNames, branchShortnames,
            } = filters;

            const isACQ = filterType === ReportType.ACQUIRER_TRANSACTIONS;
            const isPostings = filterType === ReportType.ACCOUNTING_RECORDS;

            let translationsKey = 'posTransactions';

            if (isACQ) {
                translationsKey = 'acqTransactions';
            }

            if (isPostings) {
                translationsKey = 'accountingRecords';
            }
            
            return (
                <>
                    <div className="popover-fields-wrapper">
                        <SectionTitle title={t('reports.results.filterTypeSelected')} titleExtraClass="light-grey" />
                        {t(`reports.reportsTypes.${translationsKey === 'acqTransactions' ? 'acquirerTransactions' : translationsKey}`)}
                    </div>
                    {
                        tenantsIds && tenantsIds.length > 0 && (
                            <div className="popover-fields-wrapper">
                                <SectionTitle title={t('reports.results.tenantsSelected')} titleExtraClass="light-grey" />
                                <div className="popover-fields">
                                    {
                                        tenantsIds.map((tenant: string) => {
                                            return (
                                                <span key={`key-${tenant}`} className="popover-chip">
                                                    {tenant}
                                                </span>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                    {
                    columns && columns.length > 0 && (
                        <div className="popover-fields-wrapper">
                            <SectionTitle title={t('reports.results.columnsSelected')} titleExtraClass="light-grey" />
                            <div className="popover-fields">
                                {
                                        columns.map((col: string) => {
                                            return (
                                                <span key={col} className="popover-chip">
                                                    {t(`enums.reportsColumns.${col}`)}
                                                </span>
                                            );
                                        })
                                    }
                            </div>
                        </div>
                    )}
                    {
                        Object.entries(filters).map(([key, value]) => {
                            if (value === null || value === '') return null;
                            if (['fromSavedFilter', 'columns', 'tenantsIds', 'sortColumns'].includes(key)) return null;

                            if (key === 'matchState' || key === 'processState' || key === 'dcc') {
                                const processStateValue = value === true ? ProcessState.EXPORTED : ProcessState.NOT_EXPORTED;
                                const dccValue = value === true ? DCCState.DCC_ONLY : DCCState.NO_DCC;

                                const processStateLabel = t(`enums.processState.${processStateValue}`);
                                const dccLabel = t(`enums.DCC.${dccValue}`);
                                const matchStateLabel = t(`enums.matchState.${value}`);
                                let finalLabel;
                                
                                if (key === 'matchState') finalLabel = matchStateLabel;
                                if (key === 'processState') finalLabel = processStateLabel;
                                if (key === 'dcc') finalLabel = dccLabel;

                                if (isPostings && key === 'processState') {
                                    finalLabel = t(`enums.processState.${value}`);
                                }

                                return (
                                    <div className="popover-fields-wrapper" key={`div-${key}`}>
                                        <SectionTitle title={t(`reports.${translationsKey}.filters.${key}`)} titleExtraClass="light-grey" />
                                        <span key={finalLabel} className="popover-chip">
                                            {finalLabel}
                                        </span>
                                    </div>
                                );
                            }

                            if (key === 'applicationNames') {
                                return applicationNames && applicationNames.length > 0 && (
                                    <div className="popover-fields-wrapper" key={`key-${key}-value`}>
                                        <SectionTitle title={t('reports.results.paymentsSelected')} titleExtraClass="light-grey" />
                                        <div className="popover-fields">
                                            {
                                                applicationNames.map((paymentName: string) => {
                                                    return (
                                                        <span key={paymentName} className="popover-chip">
                                                            <SvgIcon paymentMethod={paymentName} title={paymentName} />
                                                            {paymentName}
                                                        </span>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                );
                            }

                            if (key === 'branchShortnames') {
                                return branchShortnames && branchShortnames.length > 0 && (
                                    <div className="popover-fields-wrapper" key={`key-${key}-value`}>
                                        <SectionTitle title={t(`reports.${translationsKey}.filters.branchShortname`)} titleExtraClass="light-grey" />
                                        <div className="popover-fields">
                                            {
                                                branchShortnames.map((name: string) => {
                                                    return (
                                                        <span key={`branch-${name}`} className="popover-chip">
                                                            {name}
                                                        </span>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                );
                            }

                            return (
                                <div className="popover-fields-wrapper" key={`key-${key}`}>
                                    <SectionTitle title={t(`reports.${translationsKey}.filters.${key}`)} titleExtraClass="light-grey" />
                                    {value}
                                </div>
                            );
                        })
                    }
                </>
            );
        }
    };

    const renderOpenItemListFilters = () => {
        if (filters) {
            return (
                <>
                    <div className="popover-fields-wrapper">
                        <SectionTitle title={t('reports.results.filterTypeSelected')} titleExtraClass="light-grey" />
                        {t('reports.reportsTypes.openItemList')}
                    </div>
                    {
                        Object.entries(filters).map(([key, value]) => {
                            if (['fromSavedFilter'].includes(key)) return null;
                            return (
                                <div className="popover-fields-wrapper" key={`key-${key}`}>
                                    <SectionTitle title={t(`reports.openItem.review.${key}`)} titleExtraClass="light-grey" />
                                    {value}
                                </div>
                            );
                        })
                    }
                </>
            );
        }
    };

    return (
        <Popover
            open
            onClose={onClosePopover}
            anchorEl={anchorElem}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
            }}
            transformOrigin={
                {
                    horizontal: 'right',
                    vertical: 'top',
                }
            }
            
        >
            <div className="popover-content" data-testid={testId}>
                {(filterType === ReportType.POS_TRANSACTIONS || filterType === ReportType.ACQUIRER_TRANSACTIONS || filterType === ReportType.ACCOUNTING_RECORDS) && renderFilters()}
                {filterType === ReportType.OPEN_ITEM_LIST && renderOpenItemListFilters()}
            </div>
        </Popover>
    );
};

export default withTranslation()(FiltersAppliedPopover);
