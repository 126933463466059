import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { GenericSearchResult } from '../../constants/types';

interface OwnProps {
    highlightedProperties: GenericSearchResult[] | undefined;
    field: string;
    value: string;
    searchedValue: string;
}

const replaceTags = (text: string, exacMatch: boolean) => {
    const findEm = '<em>';
    const regEm = new RegExp(findEm, 'g');

    const newtext = text.replace(regEm, `${exacMatch ? '<span class="withHighlight match">' : '<span class="withHighlight fuzzy">'}`);

    const findCloseEm = '</em>';
    const regCloseEm = new RegExp(findCloseEm, 'g');

    return newtext.replace(regCloseEm, '</span>');
};

const WithHighlight = (props: OwnProps) => {
    const {
        highlightedProperties, field, value, searchedValue,
    } = props;

    let highlightedFieldToSend: JSX.Element = <>{value}</>;

    if (highlightedProperties && highlightedProperties.length > 0) {
        highlightedProperties.forEach((element: GenericSearchResult) => {
            if (element.highlightedProperties[field]) {
                const { item } = element;

                const isExacMatch = value && searchedValue && value.toLowerCase().includes(searchedValue.toLowerCase());

                if (item[field] === value) {
                    const highlightedField = element.highlightedProperties[field][0].string;
                    highlightedFieldToSend = <div data-testid="highlight-wrapper">{ ReactHtmlParser(replaceTags(highlightedField, isExacMatch || false)) }</div>;
                }
            }
        });
    }
    return highlightedFieldToSend;
};

export default WithHighlight;
