import axios from 'axios';
import React, { Component } from 'react';
import {
    Map, TileLayer, Marker, Popup,
} from 'react-leaflet';

interface OwnProps {
    country: string;
    city: string;
    address: string;
    zipCode: string;
    isLoadingMap(isLoading: boolean): void;
}

interface OwnState {
    currentLat: number;
    currentLon: number;
    isFetching: boolean;
}

class CustomMap extends Component<OwnProps, OwnState> {
    state = { currentLat: -1, currentLon: -1, isFetching: false }

    componentDidMount() {
        this.checkURL();
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any) {
        const {
            country, city, address, zipCode,
        } = prevProps;
        const {
            country: newCountry, city: newCity, address: newAddress, zipCode: newZipCode,
        } = this.props;

        if ((newCountry && newCountry !== country) || (newCity && newCity !== city) || (newAddress && newAddress !== address) || (newZipCode && newZipCode !== zipCode)) {
            this.checkURL();
        }
    }

    checkURL = () => {
        const {
            address, city, country, zipCode, isLoadingMap,
        } = this.props;
        this.setState({
            isFetching: true,
        });
        isLoadingMap(true);

        const url = `https://nominatim.openstreetmap.org/search.php?street=${address}&city=${city}&country=${country}&postalcode=${zipCode}&polygon_geojson=1&format=jsonv2`;
        // if we dont have this url in localStorage, we fetch new data and saves it to localStorage
        const localData = localStorage.getItem(url);
        if (!localData) {
            axios.get(url)
                .then((response) => {
                    const { data } = response;
                    if (data && data.length > 0) {
                        this.setState({
                            currentLat: data[0].lat,
                            currentLon: data[0].lon,
                            isFetching: false,
                        });
                        isLoadingMap(false);
                        const obj = {
                            lat: data[0].lat,
                            lon: data[0].lon,
                        };
                        localStorage.setItem(url, JSON.stringify(obj));
                    } else {
                        const invalidObj = { lat: -1, lon: -1 };
                        localStorage.setItem(url, JSON.stringify(invalidObj));
                        isLoadingMap(false);
                        this.setState({
                            isFetching: false,
                        });
                    }
                })
                .catch(() => {
                    this.setState({
                        isFetching: false,
                    });
                    isLoadingMap(false);
                });
        } else {
            const localDataObj = JSON.parse(localData);
            this.setState({
                currentLat: localDataObj.lat,
                currentLon: localDataObj.lon,
                isFetching: false,
            });
            isLoadingMap(false);
        }
    }

    defineMapZoom = (): number => {
        const {
            country, city, address,
        } = this.props;
        const { currentLat, currentLon } = this.state;

        const hasCountry = currentLat && currentLon && country.trim().length > 0;
        const hasAddress = currentLat && currentLon && address.trim().length > 0 && address !== ' ';
        const hasCity = currentLat && currentLon && city.trim().length > 0;

        if (hasCountry && hasCity && hasAddress) {
            return 15;
        }
        if (hasAddress) {
            return 15;
        }

        if (hasCity) {
            return 12;
        }
        if (hasCountry) {
            return 5;
        }

        return 0;
    }

    render() {
        const { currentLat, currentLon, isFetching } = this.state;
        const {
            country, city, address, zipCode,
        } = this.props;
        
        return (
            !isFetching && (
                <Map
                    center={[currentLat, currentLon]}
                    zoom={this.defineMapZoom()}
                    scrollWheelZoom
                    style={{
                        width: '100%',
                        height: '220px',
                    }}
                    closePopupOnClick
                >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[currentLat, currentLon]}>
                        <Popup>
                            { (currentLat !== -1 && currentLon !== -1) ? `${address} ${zipCode} ${city} ${country}` : 'N/A' }
                        </Popup>
                    </Marker>
                </Map>
            )
        );
    }
}

export default CustomMap;
