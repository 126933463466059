import React from 'react';
import { ICON, SvgIcon } from './SvgIcon';

interface OwnProps {
    title: string;
    icon?: ICON;
    titleExtraClass?: string;
    subtitle?: string;
    subtitleClass?: string;
    iconClass?: string;
    onClick?: Function;
}

const SectionTitle = (props: OwnProps) => {
    const {
        title, titleExtraClass, icon, subtitle, subtitleClass, iconClass, onClick,
    } = props;
    return (
        <div
            className={`section-title ${titleExtraClass || ''} ${onClick !== undefined ? 'section-title-clickable' : ''}`}
            onClick={(e: React.MouseEvent) => (onClick ? onClick(e) : {})}
        >
            {icon && (
                <SvgIcon icon={icon} className={iconClass || ''} />
            )}
            <span>
                {title}
                {subtitle ? <span className={`${subtitleClass || 'semilight'}`}> &nbsp; { subtitle }</span> : null}
            </span>
        </div>
    );
};

export default SectionTitle;
