/* eslint-disable no-underscore-dangle */
import { createStore, compose, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import general from './reducers/general';
import authentication from './reducers/authentication';
import report from './reducers/report';
import websocket from './reducers/websocket';
import { migration } from './migration';
// import authorization from './reducers/authorization';
// import preferences from './reducers/preferences';

const isDevVersion: boolean = process.env.NODE_ENV === 'development';
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares: any = [ReduxThunk];
const volatileReducers: any = [
    'authorization',
    'preferences',
];

const config = {
    key: 'root',
    storage,
    debug: isDevVersion,
    blacklist: volatileReducers,
    version: 1,
    migrate: migration,
};

const reducers = persistCombineReducers(config, {
    general,
    authentication,
    report,
    websocket,
});

export const configureStore = () => {
    const store = createStore(
        reducers,
        composeEnhancers(applyMiddleware(...middlewares)),
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.Cypress) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.store = store;
    }

    const persistor = persistStore(store);

    return { persistor, store };
};
