import React, { Component } from 'react';

interface OwnProps {
    nbrSteps: number;
    statusValue: number;
}
class Stepper extends Component<OwnProps> {
    renderSteps = () => {
        const { nbrSteps, statusValue } = this.props;

        const steps = [];
        for (let index = 0; index < nbrSteps; index++) {
            const isDone = index + 1 < statusValue;
            steps.push(
                <div className={`md-step ${isDone ? 'active' : ''}`} key={`step-${index}`}>
                    <div className="md-step-circle" />
                    <div className="md-step-bar-right" />
                    <div className="md-step-bar-left" />
                </div>,
            );
        }

        return steps;
    }

    render() {
        return (
            <div className="md-stepper-horizontal blue">
                { this.renderSteps()}
            </div>
        );
    }
}

export default Stepper;
