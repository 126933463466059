/**
 * provides an object that can be accessed by key dynamically
 * @typedef KeyedObject
 * @property {any} [key:string]
 */
export interface KeyedObject {
    [key: string]: any;
}

export const passwordRegex = /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?=.*[A-Z])(?=.*[a-z])(?!.*\s).*$/;

/**
 * provides an object for select options
 * @typedef SelectOption
 * @property {any} value
 * @property {any} label
 */
export type SelectOption = {
    value: any;
    label: any;
    id?: any;
}

/**
 * for use in RouteComponentProps<MatchParams>
 * @typedef MatchParams
 * @property {string} id
 * @property {string} tenantId
 * @property {string} unitId
 * @property {string} branchId
 * @property {string} acquirerId
 * @property {string} name
 * @property {string} token
 * @property {string} timeFrame
 * @property {string} chartType
 */
export interface MatchParams {
    id?: string;
    tenantId?: string;
    unitId?: string;
    branchId?: string;
    acquirerId?: string;
    name?: string;
    token?: string;
    timeFrame?: string;
    chartType?: string;
}

export interface LocationState {
    from: {
        hash: string;
        pathname: string;
        search: string;
        state: string;
    };
}

export interface HeaderTab {
    value: string;
    label: string;
}

export interface SelectOptionType {
    id: string;
    label: string;
}

export const LanguageArrayProfile: Array<SelectOption> = [
    {
        value: 'en',
        label: 'English',
    },
    {
        value: 'de',
        label: 'German',
    },
    {
        value: 'fr',
        label: 'French',
    },
];

export const LanguageArray: Array<SelectOption> = [
    {
        value: 'en',
        label: 'ENG',
    },
    {
        value: 'de',
        label: 'GER',
    },
    {
        value: 'fr',
        label: 'FRA',
    },
];

export enum StartPagesEnum {
    DASHBOARD = 'DASHBOARD',
    FACTORY_TRANSACTION_PROCESSING = 'FACTORY_TRANSACTION_PROCESSING',
    FACTORY_TRANSACTION_UPLOAD = 'FACTORY_TRANSACTION_UPLOAD',
    REPORTS = 'REPORTS',
    MASTERDATA = 'MASTERDATA',
}

export enum AllRoles {
    TREIBAUF_ADMIN = 'TREIBAUF_ADMIN',
    TREIBAUF_PROJECT_MANAGER = 'TREIBAUF_PROJECT_MANAGER',
    PREPARER_ACCOUNTANT = 'PREPARER_ACCOUNTANT',
    APPROVAL_ACCOUNTANT = 'APPROVAL_ACCOUNTANT',
    FINANCIAL_MANAGER = 'FINANCIAL_MANAGER',
    PROJECT_MANAGER = 'PROJECT_MANAGER',
    ADMIN = 'ADMIN'
}

export enum TreibaufRoles {
    TREIBAUF_ADMIN = 'TREIBAUF_ADMIN',
    TREIBAUF_PROJECT_MANAGER = 'TREIBAUF_PROJECT_MANAGER',
}

export enum CustomerRoles {
    ADMIN = 'ADMIN',
    PREPARER_ACCOUNTANT = 'PREPARER_ACCOUNTANT',
    APPROVAL_ACCOUNTANT = 'APPROVAL_ACCOUNTANT',
    FINANCIAL_MANAGER = 'FINANCIAL_MANAGER',
    PROJECT_MANAGER = 'PROJECT_MANAGER',
}

export enum AccountsTypes {
    CLARIFICATION = 'CLARIFICATION',
    COMMISSION = 'COMMISSION',
    DCC_COMMISSION = 'DCC_COMMISSION',
    DEBITOR = 'DEBITOR',
    DIFFERENCE = 'DIFFERENCE',
    DISSAGIO = 'DISSAGIO',
    PAYMENT_RECEIPT = 'PAYMENT_RECEIPT',
    FLEX_1 = 'FLEX_1',
    FLEX_2 = 'FLEX_2',
    FLEX_3 = 'FLEX_3',
    SPECIAL = 'SPECIAL',
    SUSPENSE = 'SUSPENSE',
    VAT = 'VAT',
}

export interface AllPermissions {
    CUSTOMER_SELECTION: boolean;
    CUSTOMER_CREATION: boolean;
    CUSTOMER_UPDATE: boolean;
    CUSTOMER_DELETE: boolean;
    FACTORY_READ_ALL: boolean;
    FACTORY_FILTER_READ: boolean;
    FACTORY_FILTER_CREATE: boolean;
    FACTORY_MATCH_MANUALLY: boolean;
    FACTORY_PREPARE_PAYMENT: boolean;
    FACTORY_CREATE_PDF: boolean;
    FACTORY_CERTIFY_PAYMENT: boolean;
    FACTORY_EXPORT: boolean;
    FACTORY_PROCESSING_STATE: boolean;
    FACTORY_FILE_UPLOAD: boolean;
    FACTORY_MATCH_RUN: boolean;
    MASTERDATA_ALL: boolean;
    MASTERDATA_EXTENDED: boolean;
    MASTERDATA_BASIC: boolean;
    USER_MANAGEMENT: boolean;
    TREIBAUF_USER_MANAGEMENT: boolean;
    SCHEDULER: boolean;
    SUPPORT_JOBS: boolean;
    SYSTEM_SETTINGS: boolean;
    REPORTS_GENERAL_READ: boolean;
    ONLY_BASIC: boolean;
    REPORTS: boolean;
    CHARTS: boolean;
    DOCUMENTS: boolean;
    DASHBOARD_SALES_REVENUE_WIDGET: boolean;
    DASHBOARD_PROGRESS_OF_WORK_WIDGET: boolean;
    TRANSACTION_JOURNAL: boolean;
}

export enum AllPermissionsEnum {
    CUSTOMER_SELECTION = 'CUSTOMER_SELECTION',
    CUSTOMER_CREATION = 'CUSTOMER_CREATION',
    CUSTOMER_UPDATE = 'CUSTOMER_UPDATE',
    CUSTOMER_DELETE = 'CUSTOMER_DELETE',
    FACTORY_READ_ALL = 'FACTORY_READ_ALL',
    FACTORY_FILTER_READ = 'FACTORY_FILTER_READ',
    FACTORY_FILTER_CREATE = 'FACTORY_FILTER_CREATE',
    FACTORY_MATCH_MANUALLY = 'FACTORY_MATCH_MANUALLY',
    FACTORY_PREPARE_PAYMENT = 'FACTORY_PREPARE_PAYMENT',
    FACTORY_CREATE_PDF = 'FACTORY_CREATE_PDF',
    FACTORY_CERTIFY_PAYMENT = 'FACTORY_CERTIFY_PAYMENT',
    FACTORY_EXPORT = 'FACTORY_EXPORT',
    FACTORY_PROCESSING_STATE = 'FACTORY_PROCESSING_STATE',
    FACTORY_FILE_UPLOAD = 'FACTORY_FILE_UPLOAD',
    FACTORY_MATCH_RUN = 'FACTORY_MATCH_RUN',
    MASTERDATA_EXTENDED = 'MASTERDATA_EXTENDED',
    MASTERDATA_BASIC = 'MASTERDATA_BASIC',
    USER_MANAGEMENT = 'USER_MANAGEMENT',
    TREIBAUF_USER_MANAGEMENT = 'TREIBAUF_USER_MANAGEMENT',
    SCHEDULER = 'SCHEDULER',
    SUPPORT_JOBS = 'SUPPORT_JOBS',
    SYSTEM_SETTINGS = 'SYSTEM_SETTINGS',
    ONLY_BASIC = 'ONLY_BASIC',
    REPORTS = 'REPORTS',
    CHARTS = 'CHARTS',
    DOCUMENTS = 'DOCUMENTS',
    DASHBOARD_SALES_REVENUE_WIDGET = 'DASHBOARD_SALES_REVENUE_WIDGET',
    DASHBOARD_PROGRESS_OF_WORK_WIDGET = 'DASHBOARD_PROGRESS_OF_WORK_WIDGET',
    TRANSACTION_JOURNAL = 'TRANSACTION_JOURNAL'
}

export enum SupportJobs {
    CREATE_SYSTEM_MSGS = 'CREATE_SYSTEM_MSGS',
    UNMATCH_ACQUIRER_TRANSACTION = 'UNMATCH_ACQUIRER_TRANSACTION',
    UNMATCH_ACQUIRER_FILE = 'UNMATCH_ACQUIRER_FILE',
    DELETE_ACQUIRER_FILE = 'DELETE_ACQUIRER_FILE',
    UNMATCH_POS_TRANSACTION = 'UNMATCH_POS_TRANSACTION',
    UNMATCH_POS_FILE = 'UNMATCH_POS_FILE',
    DELETE_POS_FILE = 'DELETE_POS_FILE',
    UNMATCH_PAYMENT = 'UNMATCH_PAYMENT',
    RESET_PAYMENT_TO_NOT_BOOKED = 'RESET_PAYMENT_TO_NOT_BOOKED',
    RESET_PAYMENT_TO_NOT_RELEASED = 'RESET_PAYMENT_TO_NOT_RELEASED',
    RESET_PAYMENT_TO_NOT_EXPORTED = 'RESET_PAYMENT_TO_NOT_EXPORTED',
    RESET_PAYMENT_TO_NOT_DELETED = 'RESET_PAYMENT_TO_NOT_DELETED',
    DOWNLOADED_DATA = 'DOWNLOADED_DATA',
    DELETE_DATA_UNTIL = 'DELETE_DATA_UNTIL',
    MARK_PAYMENTS_AS_EXPORTED = 'MARK_PAYMENTS_AS_EXPORTED',
    RESET_XML_CONFIGURATION = 'RESET_XML_CONFIGURATION'
}

export enum ResetPaymentType {
    NOT_BOOKED = 'NOT_BOOKED',
    NOT_RELEASED = 'NOT_RELEASED',
    NOT_EXPORTED = 'NOT_EXPORTED',
    NOT_DELETED = 'NOT_DELETED'
}

export enum TransactionType {
    ACQ_TRX = 'ACQ_TRX',
    POS_TRX = 'POS_TRX'
}

export enum SystemMessagesTypes {
    ALERT = 'ALERT',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    INFO = 'INFO'
}

export const PaymentMethodsColors = [
    {
        name: 'American Express',
        color: '#0171D2',
    },
    {
        name: 'Visa',
        color: '#212562',
    },
    {
        name: 'Mastercard',
        color: '#F7A21F',
    },
    {
        name: 'Maestro',
        color: '#EC011C',
    },
    {
        name: 'Visa Electron',
        color: '#00229F',
    },
    {
        name: 'Girocard',
        color: '#882F69',
    },
    {
        name: 'PostFinance Card',
        color: '#009688',
    },
    {
        name: 'Maestro-CH',
        color: '#CDDC39',
    },
    {
        name: 'Diners',
        color: '#F8BBD0',
    },
    {
        name: 'JCB',
        color: '#722C30',
    },
];

export const AcquirersColors = [
    {
        name: 'SIX',
        color: '#E74001',
    },
    {
        name: 'Amexco',
        color: '#016CC9',
    },
    {
        name: 'B+S',
        color: '#2FB3E8',
    },
    {
        name: 'PostFinance',
        color: '#009688',
    },
    {
        name: 'Diners',
        color: '#F8BBD0',
    },
    {
        name: 'JCB',
        color: '#722C30',
    },
];

export interface CurrencyFormat {
    currencyPosition: CurrencyPosition;
    decimalSeparator: DecimalSeparator;
    thousandsSeparator: ThousandSeparator;
}

export enum CurrencyPosition {
    START = 'START',
    END = 'END',
}

export enum DecimalSeparator {
    COMMA = 'COMMA',
    POINT = 'POINT',
}

export enum ThousandSeparator {
    COMMA = 'COMMA',
    SPACE = 'SPACE',
    APOSTROPHE = 'APOSTROPHE',
    POINT = 'POINT',
}

export enum DateFormat {
    ISO_SIMPLE_NUMERIC = 'ISO_SIMPLE_NUMERIC',
    ISO_SIMPLE_NUMERIC_REVERSED = 'ISO_SIMPLE_NUMERIC_REVERSED',
    SIMPLE_NUMERIC_US = 'SIMPLE_NUMERIC_US',
    ISO_SIMPLE_NUMERIC_POINT = 'ISO_SIMPLE_NUMERIC_POINT',
    ISO_SIMPLE_NUMERIC_REVERSED_POINT = 'ISO_SIMPLE_NUMERIC_REVERSED_POINT',
    SIMPLE_NUMERIC_US_POINT = 'SIMPLE_NUMERIC_US_POINT',
    ISO_SIMPLE_NUMERIC_SLASH = 'ISO_SIMPLE_NUMERIC_SLASH',
    ISO_SIMPLE_NUMERIC_REVERSED_SLASH = 'ISO_SIMPLE_NUMERIC_REVERSED_SLASH',
    SIMPLE_NUMERIC_US_SLASH = 'SIMPLE_NUMERIC_US_SLASH',
}

export enum TimeFormat {
    HOUR_MIN_24H = 'HOUR_MIN_24H',
    ISO_12H_AM_UPPER = 'ISO_12H_AM_UPPER',
    ISO_12H_AM_LOWER = 'ISO_12H_AM_LOWER',
    HOUR_MIN_24H_DOT = 'HOUR_MIN_24H_DOT',
    ISO_12H_AM_UPPER_DOT = 'ISO_12H_AM_UPPER_DOT',
    ISO_12H_AM_LOWER_DOT = 'ISO_12H_AM_LOWER_DOT',
    HOUR_MIN_24H_H = 'HOUR_MIN_24H_H',
    ISO_12H_AM_UPPER_H = 'ISO_12H_AM_UPPER_H',
    ISO_12H_AM_LOWER_H = 'ISO_12H_AM_LOWER_H'
}

export interface DateDisplayFormat {
    format: DateFormat;
    regexShort: string;
    regexLong: string;
}

export interface TimeDisplayFormat {
    format: TimeFormat;
    regexShort: string;
    regexLong: string;
}

export const DefaultInitialUserPermissions: AllPermissions = {
    CUSTOMER_SELECTION: false,
    CUSTOMER_CREATION: false,
    CUSTOMER_UPDATE: false,
    CUSTOMER_DELETE: false,
    FACTORY_READ_ALL: false,
    FACTORY_FILTER_READ: false,
    FACTORY_FILTER_CREATE: false,
    FACTORY_MATCH_MANUALLY: false,
    FACTORY_PREPARE_PAYMENT: false,
    FACTORY_CREATE_PDF: false,
    FACTORY_CERTIFY_PAYMENT: false,
    FACTORY_EXPORT: false,
    FACTORY_PROCESSING_STATE: false,
    FACTORY_FILE_UPLOAD: false,
    FACTORY_MATCH_RUN: false,
    MASTERDATA_ALL: false,
    MASTERDATA_EXTENDED: false,
    MASTERDATA_BASIC: false,
    USER_MANAGEMENT: false,
    TREIBAUF_USER_MANAGEMENT: false,
    SCHEDULER: false,
    SUPPORT_JOBS: false,
    SYSTEM_SETTINGS: false,
    ONLY_BASIC: false,
    REPORTS_GENERAL_READ: false,
    REPORTS: false,
    CHARTS: false,
    DOCUMENTS: false,
    DASHBOARD_PROGRESS_OF_WORK_WIDGET: false,
    DASHBOARD_SALES_REVENUE_WIDGET: false,
    TRANSACTION_JOURNAL: false,
};

export const NETWORK_ERROR = 'Network_error';

export enum OriginType {
    USER = 'USER',
    SYSTEM = 'SYSTEM'
}

export enum ModuleType {
    BATCH = 'BATCH',
    BOOKING = 'BOOKING',
    DOWNLOADER = 'DOWNLOADER',
    EXPORTER = 'EXPORTER',
    FRONTEND = 'FRONTEND',
    IMPORTER = 'IMPORTER',
    MAIN = 'MAIN',
    MATCHER = 'MATCHER',
    PDF_FILE = 'PDF_FILE',
    PERSISTENCE = 'PERSISTENCE',
    RELEASE = 'RELEASE',
    REPORTS = 'REPORTS',
    SCHEDULER = 'SCHEDULER',
    SUPPORT = 'SUPPORT',
    OPENPOS = 'OPENPOS',
    UTILITIES = 'UTILITIES',
    DATABASE = 'DATABASE',
    STATISTICS = 'STATISTICS',
    DELETE_CIRCULAR_DATA = 'DELETE_CIRCULAR_DATA',
    BANK_DATA_SYNC = 'BANK_DATA_SYNC'
}

export enum EventType {
    ACCOUNT_PROFILE_PICTURE_UPDATED = 'ACCOUNT_PROFILE_PICTURE_UPDATED',
    ACCOUNT_PROFILE_PICTURE_DELETED = 'ACCOUNT_PROFILE_PICTURE_DELETED',
    LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL',
    LOGIN_UNSUCCESSFUL = 'LOGIN_UNSUCCESSFUL',
    LOGIN_CAPTCHA_REQUIRED = 'LOGIN_CAPTCHA_REQUIRED',
    LOGIN_DISABLED = 'LOGIN_DISABLED',
    ACCOUNT_ACTIVATED = 'ACCOUNT_ACTIVATED',
    PASSWORD_RECOVERY_EMAIL_REQUESTED = 'PASSWORD_RECOVERY_EMAIL_REQUESTED',
    PASSWORD_RESET = 'PASSWORD_RESET',
    LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL',
    USER_UPDATED_PROFILE = 'USER_UPDATED_PROFILE',
    LOGOUT_EXPIREDSESSION = 'LOGOUT_EXPIREDSESSION',
    BATCH_SUCCESSFUL = 'BATCH_SUCCESSFUL',
    BATCH_FAILED = 'BATCH_FAILED',
    PDF_GENERATION_SUCCESSFUL = 'PDF_GENERATION_SUCCESSFUL',
    PDF_GENERATION_FAILED = 'PDF_GENERATION_FAILED',
    IMPORT_SUCCESSFUL = 'IMPORT_SUCCESSFUL',
    IMPORT_FAILED = 'IMPORT_FAILED',
    SUPPORT_JOB_FAILED = 'SUPPORT_JOB_FAILED',
    SUPPORT_JOB_SUCCESSFUL = 'SUPPORT_JOB_SUCCESSFUL',
    DOWNLOAD_FAILED = 'DOWNLOAD_FAILED',
    DOWNLOAD_SUCCESSFUL = 'DOWNLOAD_SUCCESSFUL',
    RELEASE_FAILED = 'RELEASE_FAILED',
    RELEASE_SUCCESSFUL = 'RELEASE_SUCCESSFUL',
    USER_RELEASED_PAYMENTS = 'USER_RELEASED_PAYMENTS',
    POS_ONLY_VOUCHER_DELETED_SUCCESSFULLY = 'POS_ONLY_VOUCHER_DELETED_SUCCESSFULLY',
    POS_ONLY_VOUCHER_DELETION_FAILED = 'POS_ONLY_VOUCHER_DELETION_FAILED',
    PAYMENT_FADED_OUT = 'PAYMENT_FADED_OUT',
    BOOKING_FAILED = 'BOOKING_FAILED',
    BOOKING_SUCCESSFUL = 'BOOKING_SUCCESSFUL',
    USER_BOOKED_PAYMENTS = 'USER_BOOKED_PAYMENTS',
    EXPORT_FAILED = 'EXPORT_FAILED',
    EXPORT_SUCCESSFUL = 'EXPORT_SUCCESSFUL',
    USER_EXPORTED_PAYMENTS = 'USER_EXPORTED_PAYMENTS',
    USER_EXPORTED_POS_ONLY = 'USER_EXPORTED_POS_ONLY',
    PAYMENTS_WITH_MISSING_BOOKINGS_DETECTED_DURING_EXPORT = 'PAYMENTS_WITH_MISSING_BOOKINGS_DETECTED_DURING_EXPORT',
    PAYMENTS_WITH_EXPORT_PROBLEMS_DETECTED_DURING_EXPORT = 'PAYMENTS_WITH_EXPORT_PROBLEMS_DETECTED_DURING_EXPORT',
    MATCHER_FAILED = 'MATCHER_FAILED',
    MATCHER_SUCCESSFUL = 'MATCHER_SUCCESSFUL',
    RECTIFIER_FAILED = 'RECTIFIER_FAILED',
    RECTIFIER_SUCCESSFUL = 'RECTIFIER_SUCCESSFUL',
    VOUCHER_EXPORTED = 'VOUCHER_EXPORTED',
    RELEASE_VOUCHER = 'RELEASE_VOUCHER',
    RELEASE_POS_ONLY = 'RELEASE_POS_ONLY',
    VOUCHER_BOOKED = 'VOUCHER_BOOKED',
    OPEN_POS_LIST_CREATED = 'OPEN_POS_LIST_CREATED',
    RECTIFY_ACQ_AUTO_SUCCESSFUL = 'RECTIFY_ACQ_AUTO_SUCCESSFUL',
    RECTIFY_ACQ_ONLY_SUCCESSFUL = 'RECTIFY_ACQ_ONLY_SUCCESSFUL',
    RECTIFY_ACQ_NORMAL_SUCCESSFUL = 'RECTIFY_ACQ_NORMAL_SUCCESSFUL',
    RECTIFY_ACQ_STORNO_SUCCESSFUL = 'RECTIFY_ACQ_STORNO_SUCCESSFUL',
    RECTIFY_ACQ_SUCCESSFUL = 'RECTIFY_ACQ_SUCCESSFUL',
    RECTIFY_POS_ONLY_SUCCESSFUL = 'RECTIFY_POS_ONLY_SUCCESSFUL',
    RECTIFY_POS_STORNO_SUCCESSFUL = 'RECTIFY_POS_STORNO_SUCCESSFUL',
    RECTIFY_POS_SUCCESSFUL = 'RECTIFY_POS_SUCCESSFUL',
    RECTIFY_POS_NORMAL_SUCCESSFUL = 'RECTIFY_POS_NORMAL_SUCCESSFUL',
    RECTIFY_POS_AUTO_SUCCESSFUL = 'RECTIFY_POS_AUTO_SUCCESSFUL',
    REPORT_FILTER_CREATED = 'REPORT_FILTER_CREATED',
    REPORT_FILTER_UPDATED = 'REPORT_FILTER_UPDATED',
    REPORT_FILTER_DELETED = 'REPORT_FILTER_DELETED',
    SCHEDULED_JOB_CREATED = 'SCHEDULED_JOB_CREATED',
    SCHEDULED_JOB_EDITED = 'SCHEDULED_JOB_EDITED',
    SCHEDULED_JOB_DELETED = 'SCHEDULED_JOB_DELETED',
    SCHEDULED_JOB_EXECUTED = 'SCHEDULED_JOB_EXECUTED',
    SCHEDULED_JOB_PAUSED = 'SCHEDULED_JOB_PAUSED',
    SCHEDULED_JOB_RESUMED = 'SCHEDULED_JOB_RESUMED',
    SCHEDULED_JOBS_IMPORTED = 'SCHEDULED_JOBS_IMPORTED',
    SCHEDULED_JOBS_PAUSED = 'SCHEDULED_JOBS_PAUSED',
    SCHEDULED_JOBS_RESUMED = 'SCHEDULED_JOBS_RESUMED',
    SUPPORT_DELETE_FILE = 'SUPPORT_DELETE_FILE',
    SUPPORT_UNMATCH_ACQ_FILE = 'SUPPORT_UNMATCH_ACQ_FILE',
    SUPPORT_UNMATCH_POS_FILE = 'SUPPORT_UNMATCH_POS_FILE',
    SUPPORT_UNMATCH_ACQ_TRX = 'SUPPORT_UNMATCH_ACQ_TRX',
    SUPPORT_UNMATCH_POS_TRX = 'SUPPORT_UNMATCH_POS_TRX',
    SUPPORT_UNMATCH_PAYMENT = 'SUPPORT_UNMATCH_PAYMENT',
    SUPPORT_DELETE_CIRCULAR_DATA = 'SUPPORT_DELETE_CIRCULAR_DATA',
    SUPPORT_DOWNLOAD_DATA = 'SUPPORT_DOWNLOAD_DATA',
    SUPPORT_RESET_PAYMENT = 'SUPPORT_RESET_PAYMENT',
    SUPPORT_RESET_XML_CONFIGURATIONS = 'SUPPORT_RESET_XML_CONFIGURATIONS',
    SUPPORT_EXPORT_PAYMENT = 'SUPPORT_EXPORT_PAYMENT',
    SUBCONTRACT_PARTNER_ADDED = 'SUBCONTRACT_PARTNER_ADDED',
    SUBCONTRACT_PARTNER_EDITED = 'SUBCONTRACT_PARTNER_EDITED',
    SUBCONTRACT_PARTNER_REMOVED = 'SUBCONTRACT_PARTNER_REMOVED',
    SUBCONTRACT_PARTNER_MOVED_TO_ANOTHER_BRANCH = 'SUBCONTRACT_PARTNER_MOVED_TO_ANOTHER_BRANCH',
    TERMINAL_ADDED = 'TERMINAL_ADDED',
    TERMINAL_EDITED = 'TERMINAL_EDITED',
    TERMINAL_REMOVED = 'TERMINAL_REMOVED',
    BRANCH_RENAMED = 'BRANCH_RENAMED',
    TERMINAL_MOVED_TO_ANOTHER_BRANCH = 'TERMINAL_MOVED_TO_ANOTHER_BRANCH',
    BRANCH_MOVED_TO_ANOTHER_TENANT = 'BRANCH_MOVED_TO_ANOTHER_TENANT',
    CUSTOMER_ADDED = 'CUSTOMER_ADDED',
    CUSTOMER_EDITED = 'CUSTOMER_EDITED',
    CUSTOMER_REMOVED = 'CUSTOMER_REMOVED',
    USER_ADDED = 'USER_ADDED',
    USER_EDITED = 'USER_EDITED',
    USER_REMOVED = 'USER_REMOVED',
    USER_LOCKED = 'USER_LOCKED',
    USER_UNLOCKED = 'USER_UNLOCKED',
    TENANT_ADDED = 'TENANT_ADDED',
    TENANT_EDITED = 'TENANT_EDITED',
    TENANT_REMOVED = 'TENANT_REMOVED',
    UNIT_ADDED = 'UNIT_ADDED',
    UNIT_EDITED = 'UNIT_EDITED',
    UNIT_REMOVED = 'UNIT_REMOVED',
    BRANCH_ADDED = 'BRANCH_ADDED',
    BRANCH_EDITED = 'BRANCH_EDITED',
    BRANCH_REMOVED = 'BRANCH_REMOVED',
    ACQUIRER_ADDED = 'ACQUIRER_ADDED',
    ACQUIRER_EDITED = 'ACQUIRER_EDITED',
    ACQUIRER_REMOVED = 'ACQUIRER_REMOVED',
    APPLICATION_ADDED = 'APPLICATION_ADDED',
    APPLICATION_REMOVED = 'APPLICATION_REMOVED',
    BANK_ACCOUNT_ADDED = 'BANK_ACCOUNT_ADDED',
    BANK_ACCOUNT_EDITED = 'BANK_ACCOUNT_EDITED',
    BANK_ACCOUNT_REMOVED = 'BANK_ACCOUNT_REMOVED',
    BANK_DATA_SYNCHRONIZATION_SUCCEEDED = 'BANK_DATA_SYNCHRONIZATION_SUCCEEDED',
    BANK_DATA_SYNCHRONIZATION_FAILED = 'BANK_DATA_SYNCHRONIZATION_FAILED',
    BOOKING_ACCOUNT_ADDED = 'BOOKING_ACCOUNT_ADDED',
    BOOKING_ACCOUNT_EDITED = 'BOOKING_ACCOUNT_EDITED',
    BOOKING_ACCOUNT_REMOVED = 'BOOKING_ACCOUNT_REMOVED',
    USER_ROLES_UPDATED = 'USER_ROLES_UPDATED',
    UNKNOWN_MASTERDATA_SET_REMOVED = 'UNKNOWN_MASTERDATA_SET_REMOVED',
    UNKNOWN_MASTERDATA_SET_RESOLVED = 'UNKNOWN_MASTERDATA_SET_RESOLVED',
    UNKNOWN_MASTERDATA_SET_UNRESOLVED = 'UNKNOWN_MASTERDATA_SET_UNRESOLVED',
    USER_CREATION_EMAIL_SENDING_UNSUCCESSFUL = 'USER_CREATION_EMAIL_SENDING_UNSUCCESSFUL',
    UNKNOWN_UNIT_DETECTED = 'UNKNOWN_UNIT_DETECTED',
    UNKNOWN_BRANCH_DETECTED = 'UNKNOWN_BRANCH_DETECTED',
    UNKNOWN_TERMINAL_NBR_DETECTED = 'UNKNOWN_TERMINAL_NBR_DETECTED',
    UNKNOWN_CONTRACT_NBR_DETECTED = 'UNKNOWN_CONTRACT_NBR_DETECTED',
    UNKNOWN_BOOKING_BOOKING_ACCOUNT_DETECTED = 'UNKNOWN_BOOKING_BOOKING_ACCOUNT_DETECTED',
    UNKNOWN_EXPORTER_BOOKING_ACCOUNT_DETECTED = 'UNKNOWN_EXPORTER_BOOKING_ACCOUNT_DETECTED',
    UNKNOWN_BANK_ACCOUNT_DETECTED = 'UNKNOWN_BANK_ACCOUNT_DETECTED',
    UNKNOWN_IMPORTER_CONVERSION_DETECTED = 'UNKNOWN_IMPORTER_CONVERSION_DETECTED',
    UNKNOWN_EXPORTER_CONVERSION_DETECTED = 'UNKNOWN_EXPORTER_CONVERSION_DETECTED',
    UNKNOWN_CONVERSION_DETECTED = 'UNKNOWN_CONVERSION_DETECTED',
    DAILY_EMAIL_SEND_FAILED = 'DAILY_EMAIL_SEND_FAILED',
    PAYMENT_VOUCHER_DELETE_FAILED = 'PAYMENT_VOUCHER_DELETE_FAILED',
    STATISTICS_CALCULATION_ERROR = 'STATISTICS_CALCULATION_ERROR',
    GENERIC_MATCHBOX_FAILED = 'GENERIC_MATCHBOX_FAILED',
    GENERIC_MATCHBOX_ERROR = 'GENERIC_MATCHBOX_ERROR',
    JIRA_SERVICE_MANAGEMENT_FAILED = 'JIRA_SERVICE_MANAGEMENT_FAILED',
    ACQUIRER_ADDED_FAILED = 'ACQUIRER_ADDED_FAILED',
    ACQUIRER_EDITED_FAILED = 'ACQUIRER_EDITED_FAILED',
    BRANCH_EDITED_FAILED = 'BRANCH_EDITED_FAILED',
    BRANCH_ADDED_FAILURE = 'BRANCH_ADDED_FAILURE',
    POS_TRX_DELETE_FAILED = 'POS_TRX_DELETE_FAILED',
    MATCHER_MANUAL_FAILED = 'MATCHER_MANUAL_FAILED',
    SCHEDULED_JOB_CREATION_FAILED = 'SCHEDULED_JOB_CREATION_FAILED',
    SCHEDULED_JOB_PAUSE_FAILED = 'SCHEDULED_JOB_PAUSE_FAILED',
    PAYMENT_PROCESS_FAILED = 'PAYMENT_PROCESS_FAILED',
    PAYMENT_FADE_OUT_FAILED = 'PAYMENT_FADE_OUT_FAILED',
    SUPPORT_DELETE_FILE_FAILED = 'SUPPORT_DELETE_FILE_FAILED',
    BOOKING_ACCOUNT_ADDED_FAILED = 'BOOKING_ACCOUNT_ADDED_FAILED',
    PAYMENT_VOUCHER_UPDATE_FAILED = 'PAYMENT_VOUCHER_UPDATE_FAILED',
    CHAIN_ADDED = 'CHAIN_ADDED',
    CHAIN_EDITED = 'CHAIN_EDITED',
    CHAIN_REMOVED = 'CHAIN_REMOVED',
    CHAIN_ALREADY_IN_QUEUE = 'CHAIN_ALREADY_IN_QUEUE',
    SCHEDULER_USER_QUEUE_ADD_CHAIN_ERROR = 'SCHEDULER_USER_QUEUE_ADD_CHAIN_ERROR',
    MATCHED_PAYMENT_PREPARED_SUCCESSFULLY = 'MATCHED_PAYMENT_PREPARED_SUCCESSFULLY'
}

export enum FileUploadStatus {
    FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS',
    FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR',
    FILE_NOT_UPLOAD = 'FILE_NOT_UPLOADED'
}

export enum JobState {
    ACTIVE = 'ACTIVE',
    ENABLE = 'ENABLE',
    DISABLE = 'DISABLE'
}

export enum ScheduleType {
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
}

export enum ChainState {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
}

export enum SchedulerTabs {
    TASKS = 'TASKS',
    CHAINS = 'CHAINS',
    JOBS = 'JOBS',
}

export enum WebsocketChannels {
    GENERAL = 'GENERAL',
    CHAIN_EXECUTION_STATUS = 'CHAIN_EXECUTION_STATUS',
    CHAIN_STATUS = 'CHAIN_STATUS',
    JOB_EXECUTION_STATUS = 'JOB_EXECUTION_STATUS',
    JOB_STATUS = 'JOB_STATUS',
    JOB_SETTING_EXECUTION_STATUS = 'JOB_SETTING_EXECUTION_STATUS',
    TRANSACTION_JOURNAL = 'TRANSACTION_JOURNAL',
    EVENT_LOG = 'EVENT_LOG',
}

export enum WebsocketChainExecutionEvents {
    CHAIN_EXECUTION_STARTED = 'CHAIN_EXECUTION_STARTED',
    CHAIN_EXECUTION_FINISHED = 'CHAIN_EXECUTION_FINISHED',
    CHAIN_EXECUTION_STOPPED = 'CHAIN_EXECUTION_STOPPED',
    CHAIN_EXECUTION_ADDED_TO_QUEUE = 'CHAIN_EXECUTION_ADDED_TO_QUEUE',
    CHAIN_EXECUTION_REMOVED_FROM_QUEUE = 'CHAIN_EXECUTION_REMOVED_FROM_QUEUE',
    CHAIN_EXECUTION_REORDERED = 'CHAIN_EXECUTION_REORDERED',
}

export enum WebsocketChainStatusEvents {
    CHAIN_CREATED = 'CHAIN_CREATED',
    CHAIN_UPDATED = 'CHAIN_UPDATED',
    CHAIN_DELETED = 'CHAIN_DELETED',
}

export enum WebsocketJobExecutionEvents {
    JOB_EXECUTION_STARTED = 'JOB_EXECUTION_STARTED',
    JOB_EXECUTION_FINISHED = 'JOB_EXECUTION_FINISHED',
}

export enum WebsocketJobStatusEvents {
    JOB_CREATED = 'JOB_CREATED',
    JOB_UPDATED = 'JOB_UPDATED',
    JOB_DELETED = 'JOB_DELETED',
}

export enum WebsocketGeneralEvents {
    CONNECTED = 'CONNECTED',
    FULLTEXT_INDEXATION_STARTED = 'FULLTEXT_INDEXATION_STARTED',
    FULLTEXT_INDEXATION_FINISHED = 'FULLTEXT_INDEXATION_FINISHED',
    FULLTEXT_INDEXATION_RUNNING = 'FULLTEXT_INDEXATION_RUNNING',
}

export enum WebsocketJobSettingExecutionEvents {
    JOB_SETTING_EXECUTION_STARTED = 'JOB_SETTING_EXECUTION_STARTED',
    JOB_SETTING_EXECUTION_FINISHED = 'JOB_SETTING_EXECUTION_FINISHED',
}

export enum WebsocketTransactionJournalEvents {
    NEW_POS_TRX = 'NEW_POS_TRX',
    NEW_ACQ_TRX = 'NEW_ACQ_TRX',
}

export enum WebsocketEventLogEvents {
    EVENT_LOG = 'EVENT_LOG',
}

export enum DocumentsType {
    ACCOUNTING_VOUCHER_PDF = 'ACCOUNTING_VOUCHER_PDF',
    EXPORT_FILES = 'EXPORT_FILES',
    OPEN_POS_PDF = 'OPEN_POS_PDF',
    POS_ONLY_PDF = 'POS_ONLY_PDF',
}

export const moduleTypeAllTenantsBlocked = [
    ModuleType.STATISTICS,
    ModuleType.BANK_DATA_SYNC,
];

export const COMISSION_AND_TURNOVER_THRESHOLD = 0.1;

export const PIE_CHART_COLORS = [
    '#01CEDE',
    '#FF6384',
    '#36A2EB',
    '#FFCE56',
    '#FF7043',
    '#3F51B5',
    '#FF5722',
    '#8BC34A',
    '#E91E63',
    '#00BFA5',
    '#FFC107',
    '#673AB7',
    '#FF9800',
    '#4CAF50',
    '#9C27B0',
    '#CDDC39',
    '#F44336',
    '#2196F3',
    '#FFEB3B',
];

export enum ErrorCode {
    FORBIDDEN = '403',
    NOT_FOUND = '404',
}

// IMPORT HISTORY
export enum ImportHistoricStatus {
    NEW = 'NEW',
    PROCESSING = 'PROCESSING',
    FINISHED = 'FINISHED',
    FAILED = 'FAILED',
    ALREADY_IMPORTED = 'ALREADY_IMPORTED',
    DUPLICATE_TRX = 'DUPLICATE_TRX',
    MASTERDATA_FAILURE = 'MASTERDATA_FAILURE',
    MISSING = 'MISSING',
    KILLED = 'KILLED',
}

export enum ImportHistoricType {
    POS_SETTING = 'POS_SETTING',
    ACQ_FILE_SUMMARY = 'ACQ_FILE_SUMMARY',
    ACQ_GENERATED_SUMMARY = 'ACQ_GENERATED_SUMMARY',
    TERMINAL = 'TERMINAL',
    CAMT053 = 'CAMT053',
    MT940 = 'MT940',
}

export interface DefaultCurrencyFormat {
    decimalSeparator: DecimalSeparator;
    position: CurrencyPosition;
    thousandSeparator: ThousandSeparator;
}
